import React, { Component } from "react";
import { Layout } from "../../components";
import AudytForm from "../../components/AudytForm";
import AudytTable from "../../components/AudytTable";
import locales from "../../constants";
import $ from "jquery";

class Audyt extends Component {
  images = {
    audytGraph: require("../../assets/img/audyt/na-czym-polega.png"),
    pelnyAudyt: require("../../assets/img/audyt/pelny-audyt.png"),
    testUx: require("../../assets/img/audyt/test-ux.png"),
    analizaKon: require("../../assets/img/audyt/analiza.png"),
    analizaRuch: require("../../assets/img/audyt/analiza-ruchu.png"),
    kosmos: require("../../assets/img/audyt/kosmos-ux.svg"),
  };

  componentDidMount() {
    setTimeout(function () {
      $(".line").addClass("show");
    }, 750);
  }

  render() {
    const { slug } = this.props.pageContext;
    const lang = "en";
    const { audytGraph, pelnyAudyt, testUx, analizaKon, analizaRuch, kosmos } =
      this.images;

    return (
      <Layout
        seo={{
          title: "Audyt UX",
          href: slug,
          lang: "en",
        }}
        langSwitch={{
          langKey: "pl",
          langSlug: "/audyt-ux/",
        }}
        header={{ icons: "#eab941" }}
      >
        <section className="container-fluid audytHero">
          <h1>UX Audit</h1>
          <h2 className="ux_text">
            Don’t count on your luck, count on solid UX/UI research
            {/* <span className="line line_1"></span>
            <span className="line line_2"></span> */}
          </h2>
        </section>
        <section className="container-fluid audytTwoCol">
          <div className="row justify-content-center">
            <div className="col-md-5 col-lg-4 audytTwoCol__col">
              <h3 className="audytHeading">The requirements are growing</h3>
              <p>
                The level of awareness and expectations of users is growing.
              </p>
              <p>
                How to cope with the pace of those changes?
                <br />
                How to find yourself in the endless maze of information?
                <br />
                What if we actually knew what our customers / users really want?
              </p>
              <p>
                {" "}
                User Experience Design comes in handy when researching users and
                their behavior. It is an area that is gaining more and more
                popularity all over the world. Because if we are to do
                something, it's best to do it wisely..{" "}
              </p>
            </div>
            <div className="col-md-5 col-lg-4 audytTwoCol__col">
              <h3 className="audytHeading">What is a UX audit?</h3>
              <p>
                It is an assessment in terms of usability, convenience of use
                and the diagnosis of key errors that make it difficult or
                impossible to use the website.{" "}
              </p>
              <p>
                An audit is carried out to check how the website works and
                whether it fulfills its purpose.
              </p>
              <p>
                It allows you to locate key errors that make it difficult or
                impossible for the user to achieve the purpose of the website
                (e.g. making a purchase, filling out the contact form, etc.). By
                improving them, we can increase the conversion rates.
              </p>
            </div>
          </div>
        </section>
        <section className="container-fluid audytGraph">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8">
              <h3 className="audytHeading">What is UX audit all about?</h3>
              <div className="audytGraph__container">
                <img src={audytGraph} alt="" className="audytGraph__image" />
                <img
                  src={kosmos}
                  alt=""
                  className="audytGraph__image--mobile"
                />
                <p className="audytGraph__p1">
                  Detect errors on your website. Places that make shopping
                  difficult or make it impossible for the users to find
                  information freely..{" "}
                </p>
                <p className="audytGraph__p2">
                  Learn more about the elements that should get highlighted
                </p>
                <p className="audytGraph__p3">
                  Find out which texts require modification or greater exposure.
                  Are they understandable and relevant to your target group?
                </p>
                <p className="audytGraph__p4">
                  Through the audit, you will improve the processes on the
                  website. You will provide the right information at the right
                  time to your potential Clients.
                </p>
                <p className="audytGraph__p5">
                  By eliminating errors, you increase your credibility and
                  reliability online.
                </p>
                <p className="audytGraph__p6">
                  Improve communication with your Clients by adapting the
                  materials to your target group.. You will understand their
                  motivations and activities on the site.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid audytMatch">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8">
              <h3 className="audytHeading">Adjust it to your needs</h3>
              <p className="audytMatch__description">
                In order for the audit to make sense and give you real benefits,
                it should be tailored to your needs.
                <br />
                Start by determining the purpose of the audit or a problem on
                your website. This will allow you to select the appropriate
                scope and form of the audit.
              </p>
              <div className="audytMatch__top">
                <div className="audytMatch__leftBox">
                  <p>Full UX audit</p>
                </div>
                <div className="audytMatch__col">
                  <img src={pelnyAudyt} alt="" />
                </div>
                <div className="audytMatch__col">
                  <ul>
                    <li>
                      Consultations and workshops, allowing us to choose the
                      right ones together
                    </li>
                    <li>
                      Basic website operation analysis (desk research, cognitive
                      journey, and ux test) audit parameters
                    </li>
                    <li>Competition analysis</li>
                    <li>Analysis of website traffic in selected areas</li>
                  </ul>
                </div>
                <div className="audytMatch__col">
                  <ul>
                    <li>
                      Analysis of the website content and its adjustment to the
                      users target group of the website
                    </li>
                    <li>Recommendations, also in visual form</li>
                    <li>Report with presentation</li>
                    <li>
                      Consultation of the audits’ results, after reading the
                      report and our recommendations
                    </li>
                  </ul>
                </div>
              </div>
              <div className="audytMatch__bottom">
                <div className="audytMatch__leftBox">
                  <p>Partial audits</p>
                </div>
                <div className="audytMatch__col">
                  <div className="audytMatch__image">
                    <img src={testUx} alt="" />
                  </div>
                  <h4>Test UX</h4>
                  <p>
                    This version of the audit can be carried out, for example,
                    when we plan changes on the website or when difficulties in
                    navigating the website have been observed.{" "}
                  </p>
                  <p>
                    As a result of such an audit, we will find out what are the
                    most important errors on the website, on which we should
                    focus.{" "}
                  </p>
                </div>
                <div className="audytMatch__col">
                  <div className="audytMatch__image">
                    <img src={analizaKon} alt="" />
                  </div>
                  <h4>Competition analysis</h4>
                  <p>
                    We can carry out this version of the audit, for example,
                    when we want to find out how our website looks compared to
                    the competition.
                  </p>
                  <p>
                    As a result of such an audit, we will find out, for example,
                    what solutions work (in this particular business), and how
                    do we look like, compared to the competition (in terms of
                    functionality and design).
                  </p>
                </div>
                <div className="audytMatch__col">
                  <div className="audytMatch__image">
                    <img src={analizaRuch} alt="" />
                  </div>
                  <h4>Web traffic analysis</h4>
                  <p>
                    We can carry out this version of the audit, for example,
                    when we notice that users fill in only a part of the forms
                    on the site or stop at the registration, or purchase
                    process.
                  </p>
                  <p>
                    As a result of such an audit, we will find out, for example,
                    which elements of the website are problematic to its users,
                    which functionalities or parts of the website they use most
                    often, and which they do not use at all.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid audytTable">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <AudytTable lang={lang} />
            </div>
          </div>
        </section>
        <section className="container-fluid audytContact">
          <div className="row justify-content-center">
            <div className="col-md-4 col-lg-3">
              <h3 className="audytHeading">
                What scope of the audit should you choose?
              </h3>
              <p>
                Write to us, our experts will be happy to help you choose the
                appropriate scope and form of the audit.
              </p>
            </div>
            <div className="col-md-6 col-lg-5">
              <AudytForm locales={locales[lang]} lang={lang} />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Audyt;
